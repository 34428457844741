export default {
  'component.customers_carousel.subtitle': 'Our customers',
  'component.customers_carousel.title': 'They Trust Us',
  'component.footer_contact_banner.action': 'Contact Us',
  'component.footer_contact_banner.title': "Let's get your project started!",
  'component.funfact.coffee': 'Cups of coffee',
  'component.funfact.countries': 'Represented countries',
  'component.funfact.line': 'Line of code',
  'component.funfact.projects': 'Projects completed',
  'component.last_blog.subtitle': 'Our News & Articles',
  'component.last_blog.title': 'Latest Blog Posts',
  'component.services_card.services.dedicated': 'Dedicated Resources',
  'component.services_card.services.devops': 'DevOps Support',
  'component.services_card.services.full_app': 'Full App Development',
  'component.services_card.subtitle': 'Khufu Offers What You Need',
  'component.services_card.title': 'Our Services',
  'component.team_carousel.description': 'We are committed to providing our customers with exceptional service \\n while offering our employees the best training.',
  'component.team_carousel.job.ceo': 'Chief Executive Officer',
  'component.team_carousel.job.cso': 'Chief Sales Officer',
  'component.team_carousel.job.fullstack': 'Fullstack Developer',
  'component.team_carousel.subtitle': 'Meet the team',
  'component.team_carousel.title': 'Expert people',
  'footer.menu.contact': 'Contact',
  'footer.menu.social': 'Social',
  'footer.resume': 'Khufu supports various business sectors in their digitalization, by offering them solutions that meet their needs, and which, in the process, revolutionize the model of SaaS applications by issuing free licenses and a solution dedicated to each client.',
  'menu.about': 'About',
  'menu.blog': 'Blog',
  'menu.contact': 'Contact',
  'menu.customers': 'Customers',
  'menu.home': 'Home',
  'menu.services': 'Services',
  'menu.services.dedicated': 'Dedicated Resources',
  'menu.services.devops': 'DevOps',
  'menu.services.full_app': 'Development',
  'page.about.header.subtitle': 'About',
  'page.about.header.title': 'About Us',
  'page.about.since.date': 'Working for customers since',
  'page.about.since.subtitle': 'Get to know us',
  'page.about.since.title': 'Build a better and complete app with Khufu agency',
  'page.about.story.content1': "The creation of Khufu came from a simple observation. Companies want to digitize their processes or need to develop projects. But they don't have the time, the inclination or the need to create and manage a complete technical team directly in-house.",
  'page.about.story.content2': 'Khufu therefore develops your application with you, whether for mobile, internet or desktop. We can also offer you dedicated technical resources, whether for the day or for a longer period.',
  'page.about.story.subtitle': 'Summary about us',
  'page.about.story.title': 'Why we created Khufu',
  'page.article.header.subtitle': 'Blog',
  'page.article.header.title': 'Article',
  'page.blog.header.subtitle': 'Blog',
  'page.blog.header.title': 'Blog',
  'page.contact.form.action.send': 'Send message',
  'page.contact.form.description': 'We are committed to providing our customers with exceptional service while offering our employees the best training.',
  'page.contact.form.email': 'Email Address',
  'page.contact.form.firstname': 'First Name',
  'page.contact.form.lastname': 'Last Name',
  'page.contact.form.message': 'Write Message',
  'page.contact.form.phone': 'Phone Number',
  'page.contact.form.subtitle': 'Contact with us',
  'page.contact.form.title': 'Write us a message',
  'page.contact.header.subtitle': 'Contact',
  'page.contact.header.title': 'Contact Us',
  'page.customers.header.subtitle': 'customers',
  'page.customers.header.title': 'Customers',
  'page.home.header.slide1.action': 'Discover More',
  'page.home.header.slide1.subtitle': 'Welcome to Khufu agency',
  'page.home.header.slide1.title': 'Additional tech resources.',
  'page.home.header.slide2.action': 'Discover More',
  'page.home.header.slide2.subtitle': 'Welcome to Khufu agency',
  'page.home.header.slide2.title': 'A complete team to develop your apps.',
  'page.home.header.slide3.action': 'Discover More',
  'page.home.header.slide3.subtitle': 'Welcome to Khufu agency',
  'page.home.header.slide3.title': 'A complete DevOps support.',
  'page.home.summary.action': 'Learn More',
  'page.home.summary.content': 'Khufu therefore develops your application with you, whether for mobile, internet or desktop. We can also offer you dedicated technical resources, whether for the day or for a longer period.',
  'page.home.summary.subtitle': 'Welcome to smart Khufu agency',
  'page.home.summary.title': 'BUILD A BETTER AND COMPLETE APP WITH KHUFU AGENCY',
  'page.services.dedicated.header.subtitle': 'Services',
  'page.services.dedicated.header.title': 'Dedicated Resources',
  'page.services.dedicated.offers.cto': 'CTO',
  'page.services.dedicated.offers.daily.title': 'Daily',
  'page.services.dedicated.offers.designer': 'Designer',
  'page.services.dedicated.offers.developer': 'Developer',
  'page.services.dedicated.offers.devops': 'DevOps',
  'page.services.dedicated.offers.int': 'Intermediate',
  'page.services.dedicated.offers.junior': 'Junior',
  'page.services.dedicated.offers.monthly.title': 'Monthly',
  'page.services.dedicated.offers.qa': 'QA Tester',
  'page.services.dedicated.offers.senior': 'Senior',
  'page.services.dedicated.services.1.content': 'A team completely dedicated to the support, creation and optimization of your product',
  'page.services.dedicated.services.1.title': 'Complete Team',
  'page.services.dedicated.services.2.content': 'Developers, designers, devOps and even QA Test available to complement your current team',
  'page.services.dedicated.services.2.title': 'Additional Resources',
  'page.services.dedicated.services.3.content': 'A performance and maintainability audit about the infra, the frontend and the backend of your product',
  'page.services.dedicated.services.3.title': 'Performance Audit',
  'page.services.dedicated.services.4.content': 'Dedicated team to improve the performance and maintainability of your project following an audit',
  'page.services.dedicated.services.4.title': 'Optimization',
  'page.services.dedicated.services.5.content': 'Dedicated resources to maintain your project, such as fixing bugs and adding features',
  'page.services.dedicated.services.5.title': 'Code Maintenance',
  'page.services.devops.header.subtitle': 'Services',
  'page.services.devops.header.title': 'DevOps Support',
  'page.services.devops.offers.1.action': '900 €',
  'page.services.devops.offers.1.title': 'Setting Up',
  'page.services.devops.offers.2.action': '1 100 € / mois',
  'page.services.devops.offers.2.title': 'Office Hours',
  'page.services.devops.offers.3.action': '3 200 € / mois',
  'page.services.devops.offers.3.title': '24/7',
  'page.services.devops.offers.alert': 'Alert Notifications',
  'page.services.devops.offers.backup': 'Automated Backups',
  'page.services.devops.offers.dashboard': 'Performance Dashboard',
  'page.services.devops.offers.free': 'Free Setting Up',
  'page.services.devops.offers.full_hours': '24/24 & 7/7 devOps availability',
  'page.services.devops.offers.incident': 'Incident Management',
  'page.services.devops.offers.office_hours': 'Office Hours devOps availability',
  'page.services.devops.offers.reporting': 'Reporting',
  'page.services.devops.offers.title': 'Our Offers',
  'page.services.devops.services.1.content': 'We set up an alert system on your infra in order to be instantly informed in the case of incident',
  'page.services.devops.services.1.title': 'Alert Notifications',
  'page.services.devops.services.2.content': 'We connect your infra to a centralized dashboard in order to monitor your services',
  'page.services.devops.services.2.title': 'Performance Dashboard',
  'page.services.devops.services.3.content': 'We set up a regular and automated backups on your infra to deal with major incidents',
  'page.services.devops.services.3.title': 'Automated Backups',
  'page.services.devops.services.4.content': 'We manage infra unexpected incidents on your infra in order to limit your product accessibility',
  'page.services.devops.services.4.title': 'Incident Management',
  'page.services.devops.services.5.content': 'We generate a regular report for you on the performance and incidents occurring on your infra',
  'page.services.devops.services.5.title': 'Reporting',
  'page.services.full_app.footer.action': '"Quotes Estimation"',
  'page.services.full_app.footer.text': 'You can do a pre-estimation of your product cost here',
  'page.services.full_app.header.subtitle': 'Services',
  'page.services.full_app.header.title': 'Full App Development',
  'page.services.full_app.offers.1.action': 'Contact Us',
  'page.services.full_app.offers.1.title': 'Minimum Viable Product',
  'page.services.full_app.offers.2.action': 'Contact Us',
  'page.services.full_app.offers.2.title': 'Product Creation (v1)',
  'page.services.full_app.offers.3.action': 'Contact Us',
  'page.services.full_app.offers.3.title': 'Overhaul (v2)',
  'page.services.full_app.offers.ci': 'CI/CD',
  'page.services.full_app.offers.dev': 'Development',
  'page.services.full_app.offers.devops': 'DevOps',
  'page.services.full_app.offers.opti': 'Optimization',
  'page.services.full_app.offers.product': 'Product Thought',
  'page.services.full_app.offers.title': 'Our Offers',
  'page.services.full_app.offers.ux': 'UX/UI',
  'page.services.full_app.services.1.content': 'We help you in the reflection of your product by analyzing the needs of your customers',
  'page.services.full_app.services.1.title': 'Product Thought',
  'page.services.full_app.services.2.content': 'We create wireframe and design based on the needs of future users',
  'page.services.full_app.services.2.title': 'UX/UI',
  'page.services.full_app.services.3.content': 'We develop your product in a clean and maintainable way with the technologies most suited to it',
  'page.services.full_app.services.3.title': 'Development',
  'page.services.full_app.services.4.content': 'We set up CI/CD solutions to facilitate the maintenance of your product',
  'page.services.full_app.services.4.title': 'CI/CD',
  'page.services.full_app.services.5.content': 'We put in place the most robust, secure and suitable infrastructure for your product',
  'page.services.full_app.services.5.title': 'DevOps',
  'page.services.full_app.offers.audit': 'Performance Audit',
  'page.article.latest': 'Last articles'
}
