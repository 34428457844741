export default {
  'component.customers_carousel.subtitle': 'Nuestros clientes',
  'component.customers_carousel.title': 'Confían en nosotros',
  'component.footer_contact_banner.action': 'Contacto',
  'component.footer_contact_banner.title': 'Pongamos en marcha su proyecto.',
  'component.funfact.coffee': 'Tazas de café',
  'component.funfact.countries': 'Países representados',
  'component.funfact.line': 'Línea de código',
  'component.funfact.projects': 'Proyectos realizados',
  'component.last_blog.subtitle': 'Nuestras noticias y artículos',
  'component.last_blog.title': 'Últimas publicaciones en el blog',
  'component.services_card.services.dedicated': 'Recursos dedicados',
  'component.services_card.services.devops': 'Soporte DevOps',
  'component.services_card.services.full_app': 'Desarrollo completo de aplicaciones',
  'component.services_card.subtitle': 'Khufu ofrece lo que necesitas',
  'component.services_card.title': 'Nuestros servicios',
  'component.team_carousel.description': 'Nos comprometemos a proporcionar a nuestros clientes un servicio excepcional y a ofrecer a nuestros empleados la mejor formación.',
  'component.team_carousel.job.ceo': 'Director General',
  'component.team_carousel.job.cso': 'Jefe de ventas',
  'component.team_carousel.job.fullstack': 'Desarrollador Fullstack',
  'component.team_carousel.subtitle': 'Conoce al equipo',
  'component.team_carousel.title': 'Personas expertas',
  'footer.menu.contact': 'Contact',
  'footer.menu.social': 'Social',
  'footer.resume': 'Khufu apoya a diversos sectores empresariales en su digitalización, ofreciéndoles soluciones que responden a sus necesidades y que, de paso, revolucionan el modelo de las aplicaciones SaaS mediante la emisión de licencias gratuitas y una solución dedicada a cada cliente.',
  'menu.about': 'Acerca de',
  'menu.blog': 'Blog',
  'menu.contact': 'Contact',
  'menu.customers': 'Clientes',
  'menu.home': 'Inicio',
  'menu.services': 'Servicios',
  'menu.services.dedicated': 'Recursos dedicados',
  'menu.services.devops': 'DevOps',
  'menu.services.full_app': 'Desarrollo',
  'page.about.header.subtitle': 'Acerca de',
  'page.about.header.title': 'Sobre nosotros',
  'page.about.since.date': 'Trabajando para los clientes desde',
  'page.about.since.subtitle': 'Conózcanos',
  'page.about.since.title': 'Construya una aplicación mejor y más completa con la agencia Khufu',
  'page.about.story.content1': 'La creación de Khufu surgió de una simple observación. Las empresas quieren digitalizar sus procesos o necesitan desarrollar proyectos. Pero no tienen el tiempo, la inclinación o la necesidad de crear y gestionar un equipo técnico completo directamente en la empresa.',
  'page.about.story.content2': 'Por ello, Khufu desarrolla su aplicación con usted, ya sea para móvil, Internet o escritorio. También podemos ofrecerle recursos técnicos dedicados, ya sea por un día o por un periodo más largo.',
  'page.about.story.subtitle': 'Resumen sobre nosotros',
  'page.about.story.title': 'Por qué creamos a Khufu',
  'page.article.header.subtitle': 'Blog',
  'page.article.header.title': 'Artículo',
  'page.blog.header.subtitle': 'Blog',
  'page.blog.header.title': 'Blog',
  'page.contact.form.action.send': 'Enviar mensaje',
  'page.contact.form.description': 'Nos comprometemos a proporcionar a nuestros clientes un servicio excepcional y a ofrecer a nuestros empleados la mejor formación.',
  'page.contact.form.email': 'Dirección de correo electrónico',
  'page.contact.form.firstname': 'Nombre',
  'page.contact.form.lastname': 'Apellido',
  'page.contact.form.message': 'Escriba el mensaje',
  'page.contact.form.phone': 'Número de teléfono',
  'page.contact.form.subtitle': 'Contacte con nosotros',
  'page.contact.form.title': 'Escríbenos un mensaje',
  'page.contact.header.subtitle': 'Contact',
  'page.contact.header.title': 'Contacto',
  'page.customers.header.subtitle': 'clientes',
  'page.customers.header.title': 'Clientes',
  'page.home.header.slide1.action': 'Descubrir más',
  'page.home.header.slide1.subtitle': 'Bienvenido a la agencia Khufu',
  'page.home.header.slide1.title': 'Recursos tecnológicos adicionales.',
  'page.home.header.slide2.action': 'Descubrir más',
  'page.home.header.slide2.subtitle': 'Bienvenido a la agencia Khufu',
  'page.home.header.slide2.title': 'Un equipo completo para desarrollar sus aplicaciones.',
  'page.home.header.slide3.action': 'Descubrir más',
  'page.home.header.slide3.subtitle': 'Bienvenido a la agencia Khufu',
  'page.home.header.slide3.title': 'Un soporte completo de DevOps.',
  'page.home.summary.action': 'Más información',
  'page.home.summary.content': 'Por ello, Khufu desarrolla su aplicación con usted, ya sea para móvil, Internet o escritorio. También podemos ofrecerle recursos técnicos dedicados, ya sea por un día o por un periodo más largo.',
  'page.home.summary.subtitle': 'Bienvenido a la agencia smart Khufu',
  'page.home.summary.title': 'CONSTRUYA UNA APLICACIÓN MEJOR Y MÁS COMPLETA CON LA AGENCIA KHUFU',
  'page.services.dedicated.header.subtitle': 'Servicios',
  'page.services.dedicated.header.title': 'Recursos dedicados',
  'page.services.dedicated.offers.cto': 'CTO',
  'page.services.dedicated.offers.daily.title': 'Diario',
  'page.services.dedicated.offers.designer': 'Diseñador',
  'page.services.dedicated.offers.developer': 'Desarrollador',
  'page.services.dedicated.offers.devops': 'DevOps',
  'page.services.dedicated.offers.int': 'Intermedio',
  'page.services.dedicated.offers.junior': 'Junior',
  'page.services.dedicated.offers.monthly.title': 'Mensualmente',
  'page.services.dedicated.offers.qa': 'Probador de control de calidad',
  'page.services.dedicated.offers.senior': 'Senior',
  'page.services.dedicated.services.1.content': 'Un equipo completamente dedicado al apoyo, creación y optimización de su producto',
  'page.services.dedicated.services.1.title': 'Equipo completo',
  'page.services.dedicated.services.2.content': 'Desarrolladores, diseñadores, devOps e incluso QA Test disponibles para complementar su equipo actual',
  'page.services.dedicated.services.2.title': 'Recursos adicionales',
  'page.services.dedicated.services.3.content': 'Una auditoría de rendimiento y mantenimiento sobre la infraestructura, el frontend y el backend de su producto',
  'page.services.dedicated.services.3.title': 'Auditoría de resultados',
  'page.services.dedicated.services.4.content': 'Equipo dedicado a mejorar el rendimiento y la capacidad de mantenimiento de su proyecto tras una auditoría',
  'page.services.dedicated.services.4.title': 'Optimización',
  'page.services.dedicated.services.5.content': 'Recursos dedicados al mantenimiento de su proyecto, como la corrección de errores y la adición de funciones',
  'page.services.dedicated.services.5.title': 'Código de mantenimiento',
  'page.services.devops.header.subtitle': 'Servicios',
  'page.services.devops.header.title': 'Soporte DevOps',
  'page.services.devops.offers.1.action': '900 €',
  'page.services.devops.offers.1.title': 'Configuración',
  'page.services.devops.offers.2.action': '1 100 € / mes',
  'page.services.devops.offers.2.title': 'Horario de oficina',
  'page.services.devops.offers.3.action': '3 200 € / mes',
  'page.services.devops.offers.3.title': '24/7',
  'page.services.devops.offers.alert': 'Notificaciones de alerta',
  'page.services.devops.offers.backup': 'Copias de seguridad automatizadas',
  'page.services.devops.offers.dashboard': 'Cuadro de mando de rendimiento',
  'page.services.devops.offers.free': 'Instalación gratuita',
  'page.services.devops.offers.full_hours': 'Disponibilidad de devOps 24/24 y 7/7',
  'page.services.devops.offers.incident': 'Gestión de incidentes',
  'page.services.devops.offers.office_hours': 'Horario de oficina disponibilidad de devOps',
  'page.services.devops.offers.reporting': 'Informar',
  'page.services.devops.offers.title': 'Nuestras ofertas',
  'page.services.devops.services.1.content': 'Establecemos un sistema de alerta en su infra para estar informado al instante en caso de incidente',
  'page.services.devops.services.1.title': 'Notificaciones de alerta',
  'page.services.devops.services.2.content': 'Conectamos su infraestructura a un panel de control centralizado para supervisar sus servicios',
  'page.services.devops.services.2.title': 'Cuadro de mando de rendimiento',
  'page.services.devops.services.3.content': 'Establecemos una copia de seguridad periódica y automatizada de su infraestructura para hacer frente a incidencias importantes',
  'page.services.devops.services.3.title': 'Copias de seguridad automatizadas',
  'page.services.devops.services.4.content': 'Gestionamos los incidentes inesperados en su infra con el fin de limitar la accesibilidad de su producto',
  'page.services.devops.services.4.title': 'Gestión de incidentes',
  'page.services.devops.services.5.content': 'Generamos un informe periódico para usted sobre el rendimiento y las incidencias que se producen en su infraestructura',
  'page.services.devops.services.5.title': 'Informar',
  'page.services.full_app.footer.action': '"Estimación de cotizaciones"',
  'page.services.full_app.footer.text': 'Puede hacer una estimación previa del coste de su producto aquí',
  'page.services.full_app.header.subtitle': 'Servicios',
  'page.services.full_app.header.title': 'Desarrollo completo de aplicaciones',
  'page.services.full_app.offers.1.action': 'Contacto',
  'page.services.full_app.offers.1.title': 'Producto mínimo viable',
  'page.services.full_app.offers.2.action': 'Contacto',
  'page.services.full_app.offers.2.title': 'Creación de productos (v1)',
  'page.services.full_app.offers.3.action': 'Contacto',
  'page.services.full_app.offers.3.title': 'Revisión (v2)',
  'page.services.full_app.offers.ci': 'CI/CD',
  'page.services.full_app.offers.dev': 'Desarrollo',
  'page.services.full_app.offers.devops': 'DevOps',
  'page.services.full_app.offers.opti': 'Optimización',
  'page.services.full_app.offers.product': 'Producto Pensamiento',
  'page.services.full_app.offers.title': 'Nuestras ofertas',
  'page.services.full_app.offers.ux': 'UX/UI',
  'page.services.full_app.services.1.content': 'Le ayudamos en la reflexión de su producto analizando las necesidades de sus clientes',
  'page.services.full_app.services.1.title': 'Producto Pensamiento',
  'page.services.full_app.services.2.content': 'Creamos un wireframe y un diseño basado en las necesidades de los futuros usuarios',
  'page.services.full_app.services.2.title': 'UX/UI',
  'page.services.full_app.services.3.content': 'Desarrollamos su producto de forma limpia y mantenible con las tecnologías más adecuadas',
  'page.services.full_app.services.3.title': 'Desarrollo',
  'page.services.full_app.services.4.content': 'Establecemos soluciones CI/CD para facilitar el mantenimiento de su producto',
  'page.services.full_app.services.4.title': 'CI/CD',
  'page.services.full_app.services.5.content': 'Ponemos en marcha la infraestructura más sólida, segura y adecuada para su producto',
  'page.services.full_app.services.5.title': 'DevOps',
  'page.services.full_app.offers.audit': 'Auditoría de desempeño',
  'page.article.latest': 'Ultimos articulos'
}
