import itranslations from './translations'

import logoLight from '@/images/logo.png'
import logoDark from '@/images/logo2.png'

import sliderOne01 from '@/images/bg/m-dedicated.jpg'
import sliderOne02 from '@/images/bg/m-fullapp.jpg'
import sliderOne03 from '@/images/bg/m-devops.jpg'

import sliderTwo01 from '@/images/slider/2_1.jpg'
import sliderTwo02 from '@/images/slider/2_2.jpg'
import sliderTwo03 from '@/images/slider/2_3.jpg'

import sliderThree01 from '@/images/slider/3_1.jpg'
import sliderThree02 from '@/images/slider/3_2.jpg'

import blogImage1 from '@/images/blog/1.jpg'
import blogImage2 from '@/images/blog/2.jpg'
import blogImage3 from '@/images/blog/3.jpg'
import blogImage4 from '@/images/blog/4.jpg'
import blogImage5 from '@/images/blog/5.jpg'
import blogImage6 from '@/images/blog/6.jpg'

import blogImageTwo1 from '@/images/blog/14.jpg'
import blogImageTwo2 from '@/images/blog/15.jpg'

import blogS1 from '@/images/blog/11.jpg'
import blogS2 from '@/images/blog/12.jpg'
import blogS3 from '@/images/blog/13.jpg'

import commentImage1 from '@/images/blog/9.jpg'
import commentImage2 from '@/images/blog/10.jpg'

import portfolio01 from '@/images/portfolio/1.jpg'
import portfolio02 from '@/images/portfolio/2.jpg'
import portfolio03 from '@/images/portfolio/3.jpg'
import portfolio04 from '@/images/portfolio/4.jpg'
import portfolio05 from '@/images/portfolio/5.jpg'
import portfolio06 from '@/images/portfolio/6.jpg'

import portfolioD01 from '@/images/portfolio/7.jpg'
import portfolioD02 from '@/images/portfolio/8.jpg'

import serviceOne01 from '@/images/home_1/6.jpg'
import serviceOne02 from '@/images/home_1/7.jpg'
import serviceOne03 from '@/images/home_1/8.jpg'

import trustClient01 from '@/images/home_1/4.jpg'

import ClientCarousel01 from '@/images/client/1.png'
import ClientCarousel02 from '@/images/client/2.png'
import ClientCarousel03 from '@/images/client/3.png'
import ClientCarousel04 from '@/images/client/4.png'
import ClientCarousel05 from '@/images/client/5.png'

import aboutOne01 from '@/images/about/1.jpg'
import aboutOne02 from '@/images/about/2.jpg'

import team01 from '@/images/team/1.jpg'
import team02 from '@/images/team/2.jpg'
import team03 from '@/images/team/3.jpg'
import team04 from '@/images/team/4.jpg'
import team05 from '@/images/team/5.jpg'

import video01 from '@/images/about/3.jpg'

import testimonial01 from '@/images/home_1/t1.jpg'
import testimonial02 from '@/images/home_1/t2.jpg'
import testimonial03 from '@/images/home_1/t3.jpg'

import featureTab01 from '@/images/home_1/c1.jpg'
import featureTab02 from '@/images/home_1/c2.jpg'
import featureTab03 from '@/images/home_1/c3.jpg'

import video02 from '@/images/home_1/3.jpg'

import aboutTwo01 from '@/images/home_1/2.jpg'
import aboutTwo02 from '@/images/home_1/1.jpg'

import featureTwo01 from '@/images/home_1/5.jpg'

export const translations = itranslations

export const LogoImage = {
  light: logoLight,
  dark: logoDark
}

export const NavLinks = [
  {
    name: 'menu.home',
    url: '/'
  },
  {
    name: 'menu.about',
    url: '/about'
  },
  {
    name: 'menu.services',
    subItems: [
      {
        name: 'menu.services.dedicated',
        url: '/services/dedicated-resources'
      },
      {
        name: 'menu.services.full_app',
        url: '/services/full-app-development'
      },
      {
        name: 'menu.services.devops',
        url: '/services/devops-support'
      }
    ]
  },
  {
    name: 'menu.customers',
    url: '/customers'
  },
  {
    name: 'menu.blog',
    url: '/blog'
  },
  {
    name: 'menu.contact',
    url: '/contact'
  }
]

export const SliderOneData = [
  {
    image: sliderOne01,
    subTitle: 'page.home.header.slide1.subtitle',
    title: 'page.home.header.slide1.title',
    button: {
      label: 'page.home.header.slide1.action',
      url: '/services/dedicated-resources'
    }
  },
  {
    image: sliderOne02,
    subTitle: 'page.home.header.slide2.subtitle',
    title: 'page.home.header.slide2.title',
    button: {
      label: 'page.home.header.slide2.action',
      url: '/services/full-app-development'
    }
  },
  {
    image: sliderOne03,
    subTitle: 'page.home.header.slide3.subtitle',
    title: 'page.home.header.slide3.title',
    button: {
      label: 'page.home.header.slide3.action',
      url: '/services/devops'
    }
  }
]

export const SliderTwoData = [
  {
    image: sliderTwo01,
    subTitle: 'WELCOME TO KHUFU AGENCY',
    title: 'DIGITAL\nMARKETING\nAGENCY.',
    button: {
      label: 'DISCOVER MORE',
      url: '/about'
    }
  },
  {
    image: sliderTwo02,
    subTitle: 'WELCOME TO KHUFU AGENCY',
    title: 'DIGITAL\nMARKETING\nAGENCY.',
    button: {
      label: 'DISCOVER MORE',
      url: '/about'
    }
  },
  {
    image: sliderTwo03,
    subTitle: 'WELCOME TO KHUFU AGENCY',
    title: 'DIGITAL\nMARKETING\nAGENCY.',
    button: {
      label: 'DISCOVER MORE',
      url: '/about'
    }
  }
]

export const SliderThreeData = [
  {
    image: sliderThree01,
    subTitle: 'WELCOME TO KHUFU AGENCY',
    title: 'SMART WEB\n DESIGN AGENCY.',
    button: {
      label: 'DISCOVER MORE',
      url: '/about'
    }
  },
  {
    image: sliderThree02,
    subTitle: 'WELCOME TO KHUFU AGENCY',
    title: 'SMART WEB\n DESIGN AGENCY.',
    button: {
      label: 'DISCOVER MORE',
      url: '/about'
    }
  }
]

export const ContactInfosBlock = {
  subTitle: 'our offices',
  title: 'offices near you',
  description:
    'We are committed to providing our customers with exceptional service while \n offering our employees the best training.'
}

export const ContactInfosList = [
  {
    title: 'austin',
    infos: [
      {
        name: '22 Texas West Hills'
      },
      {
        name: 'hello@khufu.io'
      },
      {
        name: '666 888 000'
      }
    ]
  },
  {
    title: 'boston',
    infos: [
      {
        name: '5 Federal Street Boston'
      },
      {
        name: 'hello@khufu.io'
      },
      {
        name: '666 888 000'
      }
    ]
  },
  {
    title: 'new york',
    infos: [
      {
        name: '8th Broklyn New York'
      },
      {
        name: 'hello@khufu.io'
      },
      {
        name: '666 888 000'
      }
    ]
  },
  {
    title: 'baltimore',
    infos: [
      {
        name: '3 Lombabr 50 baltimore'
      },
      {
        name: 'hello@khufu.io'
      },
      {
        name: '666 888 000'
      }
    ]
  }
]

export const ContactFormTitle = {
  subTitle: 'Contact with us',
  title: 'write us a message',
  description:
    'We are committed to providing our customers with exceptional service while \n      offering our employees the best training. '
}

export const BlogData = [
  {
    title: 'basic rules of running web agency business',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage1
  },
  {
    title: 'Become the best sale marketer',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage2
  },
  {
    title: 'Introducing latest mopaly features',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage3
  },
  {
    title: 'a deep understanding of our audience',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage4
  },
  {
    title: 'We build and activate brands insight',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage5
  },
  {
    title: 'experiences that connect with people',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage6
  }
]

export const BlogTwoData = [
  {
    title:
      'Dynamically procrastinate unique vortals with global best practices.',
    date: '20 nov',
    url: '/blog-single',
    image: blogImageTwo1
  },
  {
    title: 'Become the best sale marketer',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage2
  },
  {
    title: 'Introducing latest mopaly features',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage3
  },
  {
    title: 'a deep understanding of our audience',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage4
  },
  {
    title: 'We build and activate brands insight',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage5
  },
  {
    title:
      'Holisticly conceptualize backend scenarios via accurate technologies.',
    date: '20 nov',
    url: '/blog-single',
    image: blogImageTwo2
  },
  {
    title: 'experiences that connect with people',
    date: '20 nov',
    url: '/blog-single',
    image: blogImage6
  }
]

export const BlogHomeSection = {
  subTitle: 'our news & articles',
  title: 'latest blog posts',
  text:
    'We are committed to providing our customers with exceptional service while\n offering our employees the best training.'
}

export const BlogSidebarPost = [
  {
    title: 'basic rules of running web agency',
    image: blogS1,
    url: '/blog-single'
  },
  {
    title: 'basic rules of running web agency',
    image: blogS2,
    url: '/blog-single'
  },
  {
    title: 'basic rules of running web agency',
    image: blogS3,
    url: '/blog-single'
  }
]

export const BlogComments = [
  {
    image: commentImage1,
    name: 'David Martin',
    data: '20 Nov, 2018 - 4:00 pm',
    content:
      'Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.'
  },
  {
    image: commentImage2,
    name: 'Jessica Brown',
    data: '20 Nov, 2018 - 4:00 pm',
    content:
      'Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.'
  }
]

export const PortfolioData = [
  {
    title: 'Design Styles',
    categories: ['all', 'graphic'],
    image: portfolio01,
    url: '/portfolio-details'
  },
  {
    title: 'Design Styles',
    categories: ['all', 'graphic'],
    image: portfolio02,
    url: '/portfolio-details'
  },
  {
    title: 'Design Styles',
    categories: ['all', 'graphic'],
    image: portfolio03,
    url: '/portfolio-details'
  },
  {
    title: 'Design Styles',
    categories: ['all', 'graphic'],
    image: portfolio04,
    url: '/portfolio-details'
  },
  {
    title: 'Design Styles',
    categories: ['all', 'graphic'],
    image: portfolio05,
    url: '/portfolio-details'
  },
  {
    title: 'Design Styles',
    categories: ['all', 'graphic'],
    image: portfolio06,
    url: '/portfolio-details'
  }
]

export const PortfolioFilters = [
  { name: 'all' },
  { name: 'graphic' },
  { name: 'branding' },
  { name: 'marketing' },
  { name: 'logos' }
]

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: 'Design Styles',
  text:
    ' Tincidunt elit magnis nulla facilisis sagittis sapien nunc amet ultrices dolores sit ipsum velit purus aliquet massa fringilla leo orci. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci dolors sit amet elit amet. ',
  client: 'Jessica Brown',
  categories: [
    {
      name: 'Graphic,',
      url: '#'
    },
    {
      name: 'Illustrations,',
      url: '#'
    }
  ],
  date: '8 Nov, 2018',
  socials: [
    {
      name: 'Facebook',
      url: '#'
    },
    {
      name: 'Twitter',
      url: '#'
    },
    {
      name: 'Linkedin',
      url: '#'
    }
  ]
}

export const ServicePostData = [
  {
    title: 'Modren Design',
    text:
      'Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.',
    iconName: 'mei-settings',
    url: '/service-details'
  },
  {
    title: 'Digital products',
    text:
      'Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.',
    iconName: 'mei-transfer',
    url: '/service-details'
  },
  {
    title: 'Marketing Strategy',
    text:
      'Phaseus sit amet tristique lorem ipsum is simply free text ligua donec culis leo sus cipit.',
    iconName: 'mei-pie-chart',
    url: '/service-details'
  }
]

export const ServiceHomeTwoData = {
  subTitle: 'welcome to smart khufu web agency',
  title: 'We design digital products that \n help grow businesses.',
  text:
    'We are committed to providing our customers with exceptional service while\n offering our employees the best training.'
}

export const ServiceHomeThreeData = {
  subTitle: 'Services we are offering',
  title: 'Our Services',
  text:
    'We are committed to providing our customers with exceptional service while\n offering our employees the best training.'
}

export const ServicePostTwoData = {
  sectionContent: {
    title: 'We design digital products that \n help grow businesses.',
    subTitle: 'Services we are offering',
    text:
      'We are committed to providing our customers with exceptional service \n while offering our employees the best training.'
  },
  posts: [
    {
      title: 'Website Development',
      iconName: 'mei-web-design',
      url: '/service-details'
    },
    {
      title: 'Graphic Designing',
      iconName: 'mei-computer-graphic',
      url: '/service-details'
    },
    {
      title: 'Digital Marketing',
      iconName: 'mei-development-1',
      url: '/service-details'
    },
    {
      title: 'SEo & Content Writing',
      iconName: 'mei-development',
      url: '/service-details'
    },
    {
      title: 'App Development',
      iconName: 'mei-app-development',
      url: '/service-details'
    }
  ]
}

export const ServicePostThreeData = {
  sectionContent: {
    title: 'Let’s create something',
    subTitle: 'what we do',
    text:
      'We are committed to providing our customers with exceptional service \n while offering our employees the best training.'
  },
  posts: [
    {
      title: 'web development',
      image: serviceOne01,
      url: '/service-details'
    },
    {
      title: 'digital marketing',
      image: serviceOne02,
      url: '/service-details'
    },
    {
      title: 'product branding',
      image: serviceOne03,
      url: '/service-details'
    }
  ]
}

export const FunfactData = [
  {
    title: 'Projects Completed',
    countNumber: 19
  },
  {
    title: 'Line Of Code',
    countNumber: 25632
  },
  {
    title: 'Cups of Coffee',
    countNumber: 3625
  },
  {
    title: 'Represented Countries',
    countNumber: 6
  }
]

export const TrustClientData = {
  image: trustClient01,
  title: 'We are trusted by more than 8900 clients',
  text:
    'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incidi-dunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercita-tion ullamco laboris nisi ut aliquip ex ea commodo.',
  url: '/about'
}

export const ClientCarouselData = {
  sectionContent: {
    title: 'they trust us',
    subTitle: 'our clients',
    text:
      'We are committed to providing our customers with exceptional service \n while offering our employees the best training.'
  },
  items: [
    {
      url: '#',
      image: ClientCarousel01
    },
    {
      url: '#',
      image: ClientCarousel02
    },
    {
      url: '#',
      image: ClientCarousel03
    },
    {
      url: '#',
      image: ClientCarousel04
    },
    {
      url: '#',
      image: ClientCarousel05
    }
  ]
}

export const AboutOneData = {
  sectionContent: {
    title: 'build better website alot quicker with khufu agency',
    subTitle: 'get to know us'
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: 'Company Started',
    number: 1990
  }
}

export const TeamOneData = {
  sectionContent: {
    title: 'expert people',
    subTitle: 'meet the team',
    text:
      'We are committed to providing our customers with exceptional service \n while offering our employees the best training.'
  },
  posts: [
    {
      image: team01,
      name: 'Herman Fisher',
      designation: 'General Manager',
      url: '#',
      socials: [
        {
          name: 'Facebook',
          url: '#'
        },
        {
          name: 'Twitter',
          url: '#'
        },
        {
          name: 'Linkedin',
          url: '#'
        }
      ]
    },
    {
      image: team02,
      name: 'Charlie Kennedy',
      designation: 'Brand Designer',
      url: '',
      socials: [
        {
          name: 'Facebook',
          url: '#'
        },
        {
          name: 'Twitter',
          url: '#'
        },
        {
          name: 'Linkedin',
          url: '#'
        }
      ]
    },
    {
      image: team03,
      name: 'Helena Adkins',
      designation: 'Seinor Designer',
      url: '#',
      socials: [
        {
          name: 'Facebook',
          url: '#'
        },
        {
          name: 'Twitter',
          url: '#'
        },
        {
          name: 'Linkedin',
          url: '#'
        }
      ]
    },
    {
      image: team04,
      name: 'Virginia Farmer',
      designation: 'Marketing Manager',
      url: '#',
      socials: [
        {
          name: 'Facebook',
          url: '#'
        },
        {
          name: 'Twitter',
          url: '#'
        },
        {
          name: 'Linkedin',
          url: '#'
        }
      ]
    },
    {
      image: team05,
      name: 'Philip Hansen',
      designation: 'Co Founder',
      url: '#',
      socials: [
        {
          name: 'Facebook',
          url: '#'
        },
        {
          name: 'Twitter',
          url: '#'
        },
        {
          name: 'Linkedin',
          url: '#'
        }
      ]
    }
  ]
}

export const VideoOneData = {
  sectionContent: {
    title: 'Make amazing websites without touching cod',
    subTitle: 'the only design you need',
    text:
      'Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.'
  },
  video: {
    image: video01,
    ID: 'y2Eqx6ys1hQ',
    title: 'Watch Video'
  }
}

export const SubscribeFormData = {
  sectionContent: {
    title: 'Subscribe us',
    subTitle: 'don’t miss out our latest updates'
  }
}

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: 'our testimonials',
    title: 'happy customers'
  },
  posts: [
    {
      name: 'Cecilia Colon',
      designation: 'Director',
      image: testimonial01,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Chase Hanson',
      designation: 'CO Founder',
      image: testimonial02,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Mittie Flores',
      designation: 'Manager',
      image: testimonial03,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Carrie Sims',
      designation: 'Director',
      image: testimonial01,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Anne Stone',
      designation: 'CO Founder',
      image: testimonial02,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Hunter Brewer',
      designation: 'Manager',
      image: testimonial03,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Carrie Sims',
      designation: 'Director',
      image: testimonial01,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Anne Stone',
      designation: 'CO Founder',
      image: testimonial02,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    },
    {
      name: 'Hunter Brewer',
      designation: 'Manager',
      image: testimonial03,
      content:
        'This is due to their excellent service, competitive pricing and customer support. It’s throughly refresing to get such a personal touch. There are many variations of passages of available, but the majority have suffered alteration in some form by injected hum',
      date: '25 nov, 2018'
    }
  ]
}

export const FeatureTabData = {
  sectionContent: {
    title: 'why choose us',
    subTitle: 'our benefits',
    text:
      'We are committed to providing our customers with exceptional service while\n offering our employees the best training.'
  },
  posts: [
    {
      title: 'Latest technology',
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab01,
      lists: [
        {
          item: 'Self-contained, state-of-the-art time clock'
        },
        {
          item: 'Scalability of up to 500 employees per time clock'
        },
        {
          item: 'The ability to connect up to 32 time clocks'
        },
        {
          item: 'Employee self-enrollment'
        },
        {
          item: 'Payroll integration'
        },
        {
          item: 'Built-in backup camera to verify failed punches'
        }
      ]
    },
    {
      title: 'Awesome Support',
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab02,
      lists: [
        {
          item: 'Self-contained, state-of-the-art time clock'
        },
        {
          item: 'Scalability of up to 500 employees per time clock'
        },
        {
          item: 'The ability to connect up to 32 time clocks'
        },
        {
          item: 'Employee self-enrollment'
        },
        {
          item: 'Payroll integration'
        },
        {
          item: 'Built-in backup camera to verify failed punches'
        }
      ]
    },
    {
      title: '1 Click demo install',
      content:
        "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      image: featureTab03,
      lists: [
        {
          item: 'Self-contained, state-of-the-art time clock'
        },
        {
          item: 'Scalability of up to 500 employees per time clock'
        },
        {
          item: 'The ability to connect up to 32 time clocks'
        },
        {
          item: 'Employee self-enrollment'
        },
        {
          item: 'Payroll integration'
        },
        {
          item: 'Built-in backup camera to verify failed punches'
        }
      ]
    }
  ]
}

export const ParallaxOneData = {
  iconName: 'mei-team',
  title: 'Great things in business are never done by one person.',
  specialText: 'They’re done by a team of people.',
  text:
    'We are committed to providing our customers with exceptional service while \n offering our employees the best training.'
}

export const PortfolioHomeData = {
  sectionContent: {
    title: 'work showcase',
    subTitle: 'our portfolio',
    text:
      'We are committed to providing our customers with exceptional service while\n offering our employees the best training.'
  }
}

export const VideoTwoData = {
  sectionContent: {
    title: 'Digital Experience',
    subTitle: 'how do we works',
    text:
      'We are committed to providing our customers with exceptional service while\n offering our employees the best training.'
  },
  video: {
    title: 'Watch Video',
    ID: 'y2Eqx6ys1hQ',
    image: video02
  }
}

export const AboutTwoData = {
  sectionContent: {
    title: 'We are the Best Website agency in The World',
    subTitle: 'welcome to smart khufu web agency',
    text:
      "We are committed to providing our customers with exceptional service while offering our employees the best training. Lorem Ipsum is simply dummy text of the printing and typesetting industry has been the industry's standard dummy text ever since the 1500s."
  },
  button: {
    label: 'Learn More',
    url: '#'
  },
  gallery: [aboutTwo01, aboutTwo02]
}

export const FeatureTwoData = {
  sectionContent: {
    title: 'real experience',
    subTitle: 'our core features',
    text:
      ' We are committed to providing our customers with exceptional service while offering our employees the best training. '
  },
  posts: [
    {
      title: 'No Coding Skills Require',
      text:
        'There are many variations of passages of lorem ipsum available, but the majority have suffered.'
    },
    {
      title: 'Online Documentation',
      text:
        'There are many variations of passages of lorem ipsum available, but the majority have suffered.'
    },
    {
      title: 'SEO Optimized',
      text:
        'There are many variations of passages of lorem ipsum available, but the majority have suffered.'
    }
  ],
  image: {
    text: 'Total design freedom \n for everyone.',
    path: featureTwo01
  }
}

export const CallToActionTwoData = [
  {
    label: 'View our Recent Work',
    url: '/portfolio'
  },
  {
    label: 'Reqeust a free quote',
    url: '/contact'
  }
]
