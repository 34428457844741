export default {
  'component.customers_carousel.subtitle': 'Nos clients',
  'component.customers_carousel.title': 'Ils nous font confiance',
  'component.footer_contact_banner.action': 'Nous contacter',
  'component.footer_contact_banner.title': 'Commençons votre projet !',
  'component.funfact.coffee': 'Tasses de café',
  'component.funfact.countries': 'Pays représentés',
  'component.funfact.line': 'Ligne de code',
  'component.funfact.projects': 'Projets réalisés',
  'component.last_blog.subtitle': 'Nos nouvelles et articles',
  'component.last_blog.title': 'Derniers articles du blog',
  'component.services_card.services.dedicated': 'Ressources dédiées',
  'component.services_card.services.devops': 'Support DevOps',
  'component.services_card.services.full_app': 'Développement complet',
  'component.services_card.subtitle': 'Khufu offre ce dont vous avez besoin',
  'component.services_card.title': 'Nos services',
  'component.team_carousel.description': 'Nous nous engageons à fournir à nos clients un service exceptionnel tout en offrant à nos employés la meilleure formation possible.',
  'component.team_carousel.job.ceo': 'Directeur général',
  'component.team_carousel.job.cso': 'Directrice des ventes',
  'component.team_carousel.job.fullstack': 'Développeur Fullstack',
  'component.team_carousel.subtitle': "Rencontrez l'équipe",
  'component.team_carousel.title': 'Les experts',
  'footer.menu.contact': 'Contact',
  'footer.menu.social': 'Social',
  'footer.resume': "Khufu accompagne différents secteurs d'activité dans leur digitalisation, en leur proposant des solutions qui répondent à leurs besoins, et qui, au passage, révolutionnent le modèle des applications SaaS en délivrant des licences gratuites et une solution dédiée à chaque client.",
  'menu.about': 'À propos de',
  'menu.blog': 'Blog',
  'menu.contact': 'Contact',
  'menu.customers': 'Clients',
  'menu.home': 'Accueil',
  'menu.services': 'Services',
  'menu.services.dedicated': 'Ressources dédiées',
  'menu.services.devops': 'DevOps',
  'menu.services.full_app': 'Développement',
  'page.about.header.subtitle': 'À propos de',
  'page.about.header.title': 'A propos de nous',
  'page.about.since.date': 'Au service des clients depuis',
  'page.about.since.subtitle': 'Apprenez à nous connaître',
  'page.about.since.title': "Construire une application complète avec l'agence Khufu",
  'page.about.story.content1': "La création de Khufu est partie d'un constat simple. Les entreprises veulent numériser leurs processus ou ont besoin de développer des projets. Mais elles n'ont pas le temps, l'envie ou le besoin de créer et gérer une équipe technique complète directement en interne.",
  'page.about.story.content2': "Khufu développe donc votre application avec vous, que ce soit pour le mobile, l'internet ou le desktop. Nous pouvons également vous proposer des ressources techniques dédiées, que ce soit pour la journée ou pour une période plus longue.",
  'page.about.story.subtitle': 'Résumé à propos de nous',
  'page.about.story.title': 'Pourquoi nous avons créé Kheops',
  'page.article.header.subtitle': 'Blog',
  'page.article.header.title': 'Article',
  'page.blog.header.subtitle': 'Blog',
  'page.blog.header.title': 'Blog',
  'page.contact.form.action.send': 'Envoyer un message',
  'page.contact.form.description': 'Nous nous engageons à fournir à nos clients un service exceptionnel tout en offrant à nos employés la meilleure formation.',
  'page.contact.form.email': 'Adresse électronique',
  'page.contact.form.firstname': 'Prénom',
  'page.contact.form.lastname': 'Nom',
  'page.contact.form.message': 'Message',
  'page.contact.form.phone': 'Numéro de téléphone',
  'page.contact.form.subtitle': 'Contact avec nous',
  'page.contact.form.title': 'Écrivez-nous un message',
  'page.contact.header.subtitle': 'Contact',
  'page.contact.header.title': 'Nous contacter',
  'page.customers.header.subtitle': 'clients',
  'page.customers.header.title': 'Clients',
  'page.home.header.slide1.action': 'Découvrir plus',
  'page.home.header.slide1.subtitle': "Bienvenue à l'agence Khufu",
  'page.home.header.slide1.title': 'Ressources techniques.',
  'page.home.header.slide2.action': 'Découvrir plus',
  'page.home.header.slide2.subtitle': "Bienvenue à l'agence Khufu",
  'page.home.header.slide2.title': 'Une équipe complète pour vos besoins.',
  'page.home.header.slide3.action': 'Découvrir plus',
  'page.home.header.slide3.subtitle': "Bienvenue à l'agence Khufu",
  'page.home.header.slide3.title': 'Support DevOps complet.',
  'page.home.summary.action': 'En savoir plus',
  'page.home.summary.content': "Khufu développe donc votre application avec vous, que ce soit pour le mobile, l'internet ou le desktop. Nous pouvons également vous proposer des ressources techniques dédiées, que ce soit pour la journée ou pour une période plus longue.",
  'page.home.summary.subtitle': "Bienvenue à l'agence smart Khufu",
  'page.home.summary.title': "Construire une application complète avec l'agence Khufu",
  'page.services.dedicated.header.subtitle': 'Services',
  'page.services.dedicated.header.title': 'Ressources dédiées',
  'page.services.dedicated.offers.cto': 'CTO',
  'page.services.dedicated.offers.daily.title': 'Quotidiennement',
  'page.services.dedicated.offers.designer': 'Designer',
  'page.services.dedicated.offers.developer': 'Développeur',
  'page.services.dedicated.offers.devops': 'DevOps',
  'page.services.dedicated.offers.int': 'Intermédiaire',
  'page.services.dedicated.offers.junior': 'Junior',
  'page.services.dedicated.offers.monthly.title': 'Mensuel',
  'page.services.dedicated.offers.qa': 'QA Testeur',
  'page.services.dedicated.offers.senior': 'Senior',
  'page.services.dedicated.services.1.content': "Une équipe entièrement dédiée au soutien, à la création et à l'optimisation de votre produit",
  'page.services.dedicated.services.1.title': 'Équipe complète',
  'page.services.dedicated.services.2.content': 'Développeurs, concepteurs, devOps et même QA Test disponibles pour compléter votre équipe actuelle.',
  'page.services.dedicated.services.2.title': 'Ressources',
  'page.services.dedicated.services.3.content': "Un audit de performance et de maintenabilité de l'infrastructure, du front et du back de votre produit",
  'page.services.dedicated.services.3.title': 'Audit de performance',
  'page.services.dedicated.services.4.content': "Équipe dédiée à l'amélioration des performances et de la maintenabilité de votre projet suite à un audit",
  'page.services.dedicated.services.4.title': 'Optimisation',
  'page.services.dedicated.services.5.content': "Des ressources dédiées à la maintenance de votre projet, telles que la correction des bogues et l'ajout de fonctionnalités.",
  'page.services.dedicated.services.5.title': 'Code Maintenance',
  'page.services.devops.header.subtitle': 'Services',
  'page.services.devops.header.title': 'Support DevOps',
  'page.services.devops.offers.1.action': '900 €',
  'page.services.devops.offers.1.title': 'Mise en place',
  'page.services.devops.offers.2.action': '1 100 € / mois',
  'page.services.devops.offers.2.title': 'Heures de bureau',
  'page.services.devops.offers.3.action': '3 200 € / mois',
  'page.services.devops.offers.3.title': '24/7',
  'page.services.devops.offers.alert': "Notifications d'alerte",
  'page.services.devops.offers.backup': 'Sauvegardes automatisées',
  'page.services.devops.offers.dashboard': 'Dashboard des performances',
  'page.services.devops.offers.free': 'Mise en place gratuite',
  'page.services.devops.offers.full_hours': 'Disponibilité de devOps 24/24 & 7/7',
  'page.services.devops.offers.incident': 'Incident Management',
  'page.services.devops.offers.office_hours': 'Heures de bureau disponibilité devOps',
  'page.services.devops.offers.reporting': 'Reporting',
  'page.services.devops.offers.title': 'Nos offres',
  'page.services.devops.services.1.content': "Nous mettons en place un système d'alerte sur votre infra afin d'être informé instantanément en cas d'incident.",
  'page.services.devops.services.1.title': "Notifications d'alerte",
  'page.services.devops.services.2.content': 'Nous connectons votre infrastructure à un tableau de bord centralisé afin de contrôler vos services.',
  'page.services.devops.services.2.title': 'Dashboard des perfs',
  'page.services.devops.services.3.content': 'Nous mettons en place des sauvegardes automatisées de votre infrastructure pour faire face aux incidents majeurs.',
  'page.services.devops.services.3.title': 'Sauvegardes auto',
  'page.services.devops.services.4.content': "Nous gérons les incidents inattendus sur votre infrastructure afin de limiter l'accessibilité de vos produits.",
  'page.services.devops.services.4.title': 'Incident Management',
  'page.services.devops.services.5.content': 'Nous générons pour vous un rapport régulier sur les performances et les incidents survenus sur votre infrastructure.',
  'page.services.devops.services.5.title': 'Reporting',
  'page.services.full_app.footer.action': '"Estimation du coût"',
  'page.services.full_app.footer.text': 'Vous pouvez faire une pré-estimation du coût de votre produit ici',
  'page.services.full_app.header.subtitle': 'Services',
  'page.services.full_app.header.title': 'Développement complet',
  'page.services.full_app.offers.1.action': 'Nous contacter',
  'page.services.full_app.offers.1.title': 'Produit Minimum Viable',
  'page.services.full_app.offers.2.action': 'Nous contacter',
  'page.services.full_app.offers.2.title': 'Création (v1)',
  'page.services.full_app.offers.3.action': 'Nous contacter',
  'page.services.full_app.offers.3.title': 'Evolution (v2)',
  'page.services.full_app.offers.ci': 'CI/CD',
  'page.services.full_app.offers.dev': 'Développement',
  'page.services.full_app.offers.devops': 'DevOps',
  'page.services.full_app.offers.opti': 'Optimisation',
  'page.services.full_app.offers.product': 'Pensée produit',
  'page.services.full_app.offers.title': 'Nos offres',
  'page.services.full_app.offers.ux': 'UX/UI',
  'page.services.full_app.services.1.content': 'Nous vous aidons dans la réflexion de votre produit en analysant les besoins de vos clients.',
  'page.services.full_app.services.1.title': 'Pensée produit',
  'page.services.full_app.services.2.content': 'Nous créons un fil conducteur et un design basés sur les besoins des futurs utilisateurs.',
  'page.services.full_app.services.2.title': 'UX/UI',
  'page.services.full_app.services.3.content': 'Nous développons votre produit de manière propre et maintenable avec les technologies les plus adaptées.',
  'page.services.full_app.services.3.title': 'Développement',
  'page.services.full_app.services.4.content': 'Nous mettons en place des solutions CI/CD pour faciliter la maintenance de votre produit.',
  'page.services.full_app.services.4.title': 'CI/CD',
  'page.services.full_app.services.5.content': "Nous mettons en place l'infra la plus robuste, la plus sûre et la plus adaptée à votre produit.",
  'page.services.full_app.services.5.title': 'DevOps',
  'page.services.full_app.offers.audit': 'Audit de performance',
  'page.article.latest': 'Derniers articles'
}
